/* You can add global styles to this file, and also import other style files */
@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import './assets/styles/screen';

body {
  font: {
    family: var(--font-family);
    weight: normal;
  }

  margin: 0;
  background: var(--surface-ground);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-dialog-header {
  padding: .5rem 1.5rem;
  position: relative;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.p-dialog-content {
  padding: 0px;
}

p-confirmdialog div.p-dialog-content {
  padding: 7%
}

.p-datatable {
  .p-component-overlay {
    background-color: transparent;
  }
}

.custom-table {
  .p-datatable-wrapper {
    min-height: calc(100vh - 257px);
    max-height: calc(100vh - 257px);
  }
  &.overflow-x-hidden {
    .p-datatable-wrapper {
      overflow-x: hidden;
    }
  }
}

.custom-card {
  .p-card-content {
    padding: 0;
  }
  &.card-body {
    .p-card {
      border: 1px solid #cacaca;
      box-shadow: none;
    }
    .p-card-body {
      padding: 0;
    }
    .p-card-content {
      padding: .7rem 0;
    }
    .p-card-footer {
      padding: .7rem 0 0 0;
    }
  }
}

.custom-tab {
  $navColor: #e4e5e6;
  .p-tabview-nav {
    background-color: $navColor;
    li {
      background-color: $navColor;
      a {
        background-color: $navColor;
      }
    }
  }
  .p-tabview-nav-content {
    border-radius: 6px;
  }
  
}

.p-menubar .p-submenu-list {
  min-width: 12.5rem;
  width: auto;
}

.custom-view-only-table {
  th {
    background-color: transparent;
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #fff;
  color: #000;
  border-color: transparent;
}

.custom-table,
.custom-table-report {
  .p-datatable-header {
    padding: 0 1rem;
    border: none;
  }
}

.btn-delete .p-button {
  background-color: rgb(220 38 38);
}

.content-header {
  max-height: 100px;
}

.custom-input-number,
.custom-autocomplete,
.custom-input-mask {

  .p-inputnumber,
  .p-autocomplete-input,
  .p-inputmask {
    width: 100%;
  }
}

$width-config: (
  default: 245px,
  small: 148px
);

@function get-width($config, $key) {
  @return map-get($config, $key);
}


@include smaller {

  .p-dropdown,
  .p-inputtext {
    // width: get-width($width-config, small);
  }
}

@include middle {

  .p-dropdown,
  .p-inputtext {
    // width: get-width($width-config, default);
  }
}

.transparent-input input {
  background-color: transparent;
  color: inherit;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 0%;
}

.transparent-input-number input {
  background-color: transparent;
  color: inherit;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 0%;
}

.transparent-input-select .p-dropdown {
  background-color: transparent;
  color: inherit;
  width: 100%;
}

.transparent-input-mask input {
  background-color: transparent;
  color: inherit;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.transparent-input-select .p-dropdown-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

tr.noborder-th > th {
  border: none;
}

table .p-element {
  text-align: left;
}

.filter-label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  max-width: 90%;
}

tr.wrap-td td {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
}

.wrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: unset;
}
